import { i18n } from '@/plugins/i18n'

export let items_airClass = () => {
	return [
		{id: null, name: i18n.t("account.booking.content.classes.all_classes")},
		{id: "Y", name: i18n.t("account.booking.content.classes.econom")},
		{id: "J", name: i18n.t("account.booking.content.classes.premium")},
		{id: "C", name: i18n.t("account.booking.content.classes.business")},
		{id: "F", name: i18n.t("account.booking.content.classes.first_class")}
	]
}

export let items_charterAirClass = [
	{id: null, name: i18n.t("account.booking.content.classes.all_classes")},
	{id: "Y", name: i18n.t("account.booking.content.classes.econom")},
	{id: "C", name: i18n.t("account.booking.content.classes.business")},
]

export const items_age = [
	{id: 0, name: '<1'},
	{id: 1, name: '1'},
	{id: 2, name: '2'},
	{id: 3, name: '3'},
	{id: 4, name: '4'},
	{id: 5, name: '5'},
	{id: 6, name: '6'},
	{id: 7, name: '7'},
	{id: 8, name: '8'},
	{id: 9, name: '9'},
	{id: 10, name: '10'},
	{id: 11, name: '11'},
	{id: 12, name: '12'},
	{id: 13, name: '13'},
	{id: 14, name: '14'},
	{id: 15, name: '15'}
]
