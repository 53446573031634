<template>
	<div class='a-crm-policy'>
		<div class='a-crm-policy-header'>
			<p class='a-crm-policy-header-title'>{{ $t("crm.policy.title") }}</p>
		</div>

		<div class="a-crm-policy-card">
			<div class="a-crm-policy-card-item" v-for="(item, index) in policy" :key="index">
				{{ $moment(item.from, "YYYY-MM-DD H:m").format("YYYY-MM-DD H:m") }} - <span class="a-crm-policy-card-amount"> {{ $helpers.amount(item.amount, 'EUR', 2) }}</span>
			</div>
			<p class='a-crm-policy-card-footer'>{{ $t("crm.policy.footer") }}</p>
		</div>

	</div>
</template>

<script>

export default {
	props: ['policy'],
	data: () => ({
	}),



	computed: {
		fields () {
			return [
				{label: this.$t("crm.tourists.name"), key: 'name'},
				{label: this.$t("crm.tourists.surname"), key: 'surname'},
				{label: this.$t("crm.tourists.birthday"), key: 'birthday'},
				{label: this.$t("crm.tourists.number"), key: 'number'},
				{label: this.$t("crm.tourists.date_expiration"), key: 'date_expiration'},
				{label: this.$t("crm.tourists.nationality"), key: 'nationality'},
			];
		}
	},
	mounted() {
	},
	methods: {}

}
</script>

<style lang="scss">
@import "@/styles/global/variables";
@import "@/styles/global/mixins";

.a-crm-policy {
	margin-top: 40px;

	&-header {
		&-title {
			font-size: 16px;
			font-weight: 700;
			@include minw($grid-breakpoints-xs) {
				font-size: 20px;
			}
		}
	}

	&-card {
		background: #FFFFFF;
		box-shadow: 0px 0px 20px rgb(86 86 86 / 20%);
		border-radius: 10px;
		padding: 20px;
		margin-top: 15px;
		width: 100%;
		font-size: 14px;

		&-item{
			padding-left: 10px;
			font-size: 12px;
			font-weight: 400;
			line-height: 1.5;
			&:before {
				content: '';
				width: 5px;
				height: 5px;
				background: #00B796;
				position: absolute;
				left: 0;
				top: 6px;
			}
		}
		&-amount{
			font-weight: 700;

		}

		&-footer{
			margin-top: 15px;
			font-size: 12px;
			color: rgba(0, 0, 0, 0.5);
		}
	}

}
</style>
