<template>
    <v-dialog :value='value' :width='568' class='dialog' class-content='border-radius dialog-wrap'
              @close-dialog="$emit('close-dialog')">
        <div class='dialog-content'>
            <!--			<p class='dialog-title'>Test</p>-->
            <div v-for="(invoice, key) in data.invoices"
                 :key="key" class="dialog-item"
                 @click="setActiveInvoice(invoice, key)"
                 :class='{active: activeInvoice.key === key, unblock: invoice.unblock}'
            >
                <div class="dialog-item-title">{{ $t(`account.part_pay.${key}`)}}</div>
                <div class="dialog-item-date">{{ data.date }}</div>
                <div class="dialog-item-summ">{{ invoice.value }} ₴</div>
                <div class="dialog-item-commission" v-if="invoice.commission">{{ $t("account.buttons.commission_pay") }}</div>
            </div>
        </div>
        <div class='dialog-footer'>
            <div class='dialog-footer-col'>
                <v-button block xxsmall color='app-link green--text' @click.stop="$emit('close-dialog')">
                    {{ $t("account.buttons.cancel") }}
                </v-button>
            </div>
            <div class='dialog-footer-col'>
                <v-button block xxsmall :color='`green-persian white--text`' @click="createdInvoice()"
                          :loading='loading'>{{ $t("account.buttons.invoice") }}
                </v-button>
            </div>
        </div>
    </v-dialog>
</template>

<script>

import { cabinet } from '@/services/request';


export default {
    props: ['value', 'data', 'crmOrderId'],
    data: () => ({
        activeInvoice: null,
        loading: false
    }),
    watch:{
        value(val){
            if(val && this.data.invoices){
                Object.keys(this.data.invoices).forEach(i => {
                    if(this.data.invoices[i].active) this.activeInvoice = this.data.invoices[i];
                })
            }
        }
    },
    methods:{
        setActiveInvoice(invoice) {
            if(invoice.unblock){
                this.activeInvoice = invoice;
            }
        },
        createdInvoice(){
            this.loading = true;
            cabinet.order.createInvoice(this.crmOrderId, {key: this.activeInvoice.key}).then((r) => {
                this.$emit('close-dialog');
                window.open(r.data.url, '_blank');
            }).finally(() => this.loading = false)
        }
    }
}
</script>

<style lang="scss">
@import "@/styles/global/variables";
@import "@/styles/global/mixins";

.dialog {
    .app-popup {
        z-index: 20;
    }

    .backdrop {
        z-index: 15;
    }

    &-wrap {
        z-index: 5;
        padding: 0 !important;
        @include maxw($grid-breakpoints-xs - 1) {
            max-width: 100% !important;
            border-radius: 0 !important;
            display: grid;
            grid-template-rows: 1fr auto;
        }
    }

    &-content {
        padding: 40px;
        display: grid;
        grid-gap: 20px;
        grid-template-columns: repeat(2, 1fr);
        @include maxw($grid-breakpoints-xs - 1) {
            padding: 55px 20px 20px;
        }
    }
    &-item{
        padding: 15px;
        text-align: center;
        line-height: 22px;
        border: 2px solid $white;
        cursor: not-allowed;

        &-title{
            font-weight: 600;
            font-size: 15px;
        }
        &-date{
            color: $pink;
            font-size: 13px;
        }
        &-summ{
            color: $green-persian;
            font-size: 16px;
            font-weight: 700;
        }
        &-commission{
            color: $pink;
            font-size: 13px;
        }

    }
    .active{
        border: 2px solid $green-persian;
        border-radius: 5px;
    }
    .unblock{
        cursor: pointer;
    }

    &-title {
        font-size: 28px;
        font-weight: 700;
        @include maxw($grid-breakpoints-xs - 1) {
            font-size: 20px;
            font-weight: 400;
        }
    }

    &-footer {
        border-top: 1px solid rgba($black, .1);
        padding: 20px;
        display: flex;
        justify-content: flex-end;

        &-col {
            margin-left: 20px;
        }

        .app-btn {
            min-width: 93px !important;
            @include minw($grid-breakpoints-xs) {
                min-width: 120px !important;
            }
        }

        @include minw($grid-breakpoints-xs) {
            padding: 15px 35px;
        }
    }
}
</style>
