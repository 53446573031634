<template>
	<div>
		<div class='app-container a-crm-container'>
			<div class='a-crm-wrap'>
				<slot />
			</div>
		</div>

		<a-dialog-delete
			v-model='logout'
			:title='$t("account.dialogs.delete.title")'
			@close-dialog="logout = false"
			:nameButton='$t("account.dialogs.buttons.exit")'
			@action-dialog='logoutProfile'
			color='green-persian'
		/>
	</div>
</template>

<script>
	import ADialogDelete from '@/components/account/dialogs/delete';
	import { disablePageScroll, enablePageScroll } from 'scroll-lock';
	import { i18n } from '@/plugins/i18n'
	import { logout } from '@/services/request';

	export default {
		components: {
			ADialogDelete
		},
		data: () => ({
			logout: false,
			mobile_menu: false
		}),
		computed: {
			items() {
				return [
					{icon: 'settings', name: i18n.t("crm.my_settings"), link: 'my-settings'},
					{icon: 'list', name: i18n.t("crm.my_orders"), link: 'my-orders'},
					{icon: 'book', name: i18n.t("crm.my_base"), link: 'my-base'},
					{icon: 'exit-1', name: i18n.t("account.exit"), action: () => this.logout = true }
				]
			},
			UAHRate() {
				let UAH = this.$store.state.exchange_rates.find(el => {
						return el.code === 'UAH';
				});

				let USD = this.$store.state.exchange_rates.find(el => {
						return el.code === 'USD';
				});

				return UAH && USD ? Math.round(parseFloat(UAH.rate / USD.rate) * 100) / 100 : 0;
			}
		},
		created() {
			this.mobile_menu = window.innerWidth >= 960;

			window.addEventListener(`resize`, () => {
				this.mobile_menu = window.innerWidth >= 960;
			}, false);
		},
		methods: {
			logoutProfile() {
				logout().then(() => this.$store.dispatch('auth/logout'))
			}
		},
		watch: {
			"$route.name"() {
				this.mobile_menu = false;
			},
			mobile_menu() {
				setTimeout(() => {
					if(window.innerWidth >= 960) {
						enablePageScroll()
					} else {
						const hideEl = document.querySelector('.a-crm-menu')
						this.mobile_menu ? disablePageScroll(hideEl) : enablePageScroll(hideEl)
					}
				}, 150)
			}
		},
		beforeDestroy() {
			enablePageScroll()
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-crm {
		&-container {
			max-width: 1150px;
		}
		&-wrap {
			display: flex;
			align-items: flex-start;
			flex-direction: column-reverse;
			padding: 30px 0 60px;
			@include minw( $grid-breakpoints-sm ) {
				padding: 65px * .8 0 100px * .8;
				flex-direction: row;
			}
			@include minw( $grid-breakpoints-md ) {
				padding: 65px 0 100px;
			}
		}
		&-menu {
			flex-shrink: 0;
			width: 100%;
			@include minw( $grid-breakpoints-sm ) {
				position: sticky;
				position: -webkit-sticky;
				top: 25px;
				z-index: 5;
				width: 255px;
				margin-right: 30px;
			}
			@include maxw( $grid-breakpoints-sm - 1 ) {
				position: fixed;
				top: 70px;
				right: 0;
				bottom: 0;
				left: 0;
				z-index: 5;
				overflow-x: hidden;
				overflow-y: auto;
				background-color: $white;
			}
			&-wrap {
				@include minw( $grid-breakpoints-sm ) {
					box-shadow: 0px 0px 10px rgba(86, 86, 86, 0.1);
					border-radius: $border-radius * 2;
				}
			}
			&-currency {
				padding: 30px 20px 15px;
				@include minw( $grid-breakpoints-sm ) {
					padding: 18px 15px;
				}
				@include maxw( $grid-breakpoints-sm - 1 ) {
					display: flex;
					align-items: center;
					justify-content: space-between;
					border-bottom: 1px solid rgba($black, .1);
				}
				&-title {
					font-size: 14px;
					font-weight: 300;
				}
				&-divider {
					@include minw( $grid-breakpoints-sm ) {
						display: block;
						margin: 15px 0;
						border-bottom: 1px solid rgba($black, .1);
					}
				}
				&-link {
					font-size: 12px;
					font-weight: 400 !important;
				}
			}
			&-list {
				padding: 12px 0;
				&-link {
					font-size: 14px;
					font-weight: 400;
					display: flex;
					align-items: center;
					cursor: pointer;
					padding: 13px 20px;
					transition: background-color $transition;
					@include minw( $grid-breakpoints-sm ) {
						font-size: 13px;
						padding: 8px 16px;
					}
					&:hover,
					&.router-link-active {
						background-color: rgba($cyan-light, .6);
					}
					.app-icon {
						flex-shrink: 0;
						margin-right: 16px;
						color: $green-persian;
					}
				}
			}
		}
		&-content {
			width: 100%;
			&.small {
				@include minw( $grid-breakpoints-sm ) {
					max-width: 767px;
				}
			}
			&-box {
				padding: 20px 10px 30px;
				box-shadow: 0px 0px 10px rgba(86, 86, 86, 0.1);
				border-radius: $border-radius * 2;
				@include minw( $grid-breakpoints-xs ) {
					padding: 30px * .8 40px * .8 40px * .8;
				}
				@include minw( $grid-breakpoints-md ) {
					padding: 30px 40px 40px;
				}
			}
			&-group {
				margin-bottom: 16px;
				&:not(:last-child) {
					margin-bottom: 20px;
					@include minw( $grid-breakpoints-xs ) {
						margin-bottom: 26px;
					}
				}
				.a-select {
					margin-bottom: 20px;
				}
				&-buttons {
					.app-btn {
						padding-left: 50px;
						padding-right: 50px;
						@include maxw( $grid-breakpoints-xs - 1 ) {
							width: 100%;
						}
					}
				}
				&-checkbox {
					margin-bottom: 40px;
				}
			}
			&-title {
				margin-bottom: 20px;
				@include maxw( $grid-breakpoints-xs - 1 ) {
					margin-bottom: 15px;
					font-size: 16px;
				}
			}
			&-date {
				&-row {
					margin: 0 -10px;
				}
				&-col {
					padding-left: 10px;
					padding-right: 10px;
					flex: 0 0 calc(100% / 2);
					max-width: calc(100% / 2);
				}
				.mx-datepicker {
					margin-bottom: 20px;
				}
			}
		}
	}
</style>
